import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "order"
};
const _hoisted_2 = {
  class: "content"
};
const _hoisted_3 = {
  key: 0
};
import { reactive } from 'vue';
import { useStore } from 'vuex';
import Empty from '../../components/Empty.vue';
import StoreHeader from '../../components/storeHeader.vue';
export default {
  __name: 'order',
  setup(__props) {
    const store = useStore();
    const data = reactive({
      navData: ['全部', '交易完成', '待支付', '待发货', '已发货']
    });
    return (_ctx, _cache) => {
      const _component_van_card = _resolveComponent("van-card");
      const _component_van_tab = _resolveComponent("van-tab");
      const _component_van_tabs = _resolveComponent("van-tabs");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(StoreHeader, {
        title: "我的订单"
      }), _createElementVNode("div", _hoisted_2, [_createVNode(_component_van_tabs, null, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.navData, (item, index) => {
          return _openBlock(), _createBlock(_component_van_tab, {
            title: item,
            key: index
          }, {
            default: _withCtx(() => [item === '全部' && _unref(store).state.orderListEnd.length ? (_openBlock(), _createElementBlock("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(store).state.orderListEnd, (i, ide) => {
              return _openBlock(), _createElementBlock("div", {
                key: ide
              }, [_createVNode(_component_van_card, {
                num: i.num,
                price: i.price,
                title: i.title,
                thumb: i.pic
              }, null, 8, ["num", "price", "title", "thumb"])]);
            }), 128))])) : (_openBlock(), _createBlock(Empty, {
              key: 1
            }))]),
            _: 2
          }, 1032, ["title"]);
        }), 128))]),
        _: 1
      })])]);
    };
  }
};